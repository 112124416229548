<script>
import ModalContainer from "../ModalContainer.vue";
import RadioButton from "../forms/RadioButton";
import Datepicker from "../forms/DatePicker";

import { addDays } from "date-fns";

export default {
  components: {
    ModalContainer,
    RadioButton,
    Datepicker,
  },
  props: {
    position: {
      type: String,
    },
  },
  inject: ["registerModal", "unregisterModal"],
  data: () => ({
    isDatePickerVisible: false,
    userPickedShippingDateModel: null,
    deliveryOption: null,
  }),
  computed: {
    minUserDefinedDate() {
      return addDays(
        this.$cart.estimatedShippingDateTime
          ? new Date(this.$cart.estimatedShippingDateTime)
          : new Date(),
        1
      );
    },
    userPickedShippingDate() {
      return this.$cart.expectedShippingDateTime;
    },
    deliveryOptions() {
      const options = [];
      (this.$cart.deliveryOptions || []).forEach((o) => {
        const option = { ...o };
        switch (o.deliveryPreference) {
          case "SINGLE_DELIVERY":
            option.text = this.$globalTexts.checkout__delivery_date_single;
            option.description =
              this.$globalTexts.checkout__delivery_date_single_description;
            break;
          case "USER_DEFINED":
            option.text = this.$globalTexts.checkout__delivery_date_future;
            option.description =
              this.$globalTexts.checkout__delivery_date_future_description;
            break;
        }
        options.push(option);
      });
      return options;
    },
    isUpdateDeliveryButtonDisabled() {
      if (!this.isDatePickerVisible) return false;
      if (this.userPickedShippingDateModel) return false;
      return true;
    },
  },
  methods: {
    setDeliveryOption(option) {
      this.isDatePickerVisible = option.deliveryPreference === "USER_DEFINED";
      this.deliveryOption = option;
    },
    updateDeliveryOption() {
      if (this.deliveryOption) {
        this.$emit("update:deliveryOption", {
          deliveryPreference: this.deliveryOption.deliveryPreference,
          pickedShippingDate: this.userPickedShippingDateModel,
        });
      }
      this.close();
    },
    close() {
      this.$emit("close");
    },
  },
  mounted() {
    this.userPickedShippingDateModel = this.userPickedShippingDate
      ? new Date(this.userPickedShippingDate)
      : "";

    const activeOption = this.deliveryOptions.find((f) => f.isActive);

    this.isDatePickerVisible = this.deliveryOptions.find(
      (f) => f.isActive && f.deliveryPreference === "USER_DEFINED"
    );

    this.setDeliveryOption(activeOption);

    this.registerModal();
  },
  beforeUnmount() {
    this.unregisterModal();
  },
};
</script>

<template>
  <teleport to="body">
    <modal-container
      @close-modal="close"
      @navigate-back="close"
      position="center"
      :show-exit-button="!$isPhone"
      :show-back-button="$isPhone"
    >
      <div class="checkout-delivery-date">
        <div class="checkout-delivery-date__top">
          <div class="checkout-delivery-date__caption-container">
            <div class="checkout-delivery-date__caption">
              {{ $globalTexts.checkout__delivery_date }}
            </div>
          </div>
          <div class="checkout-delivery-date__delivery-options">
            <div
              v-for="(option, idx) in deliveryOptions"
              :key="idx"
              class="checkout-delivery-date__delivery-option"
            >
              <radio-button
                v-model="option.isActive"
                name="deliveryPreferences"
                @change="setDeliveryOption(option)"
                :text="option.text"
                :description="option.description"
              />
            </div>
          </div>
          <div
            class="checkout-delivery-date__shadow"
            v-if="isDatePickerVisible"
          ></div>
        </div>

        <div
          v-if="isDatePickerVisible"
          class="checkout-delivery-date__date-picker"
        >
          <datepicker
            v-model="userPickedShippingDateModel"
            :lower-limit="minUserDefinedDate"
          />
        </div>
        <div>
          <button
            class="primary-button checkout-delivery-date__update-delivery"
            :disabled="isUpdateDeliveryButtonDisabled"
            @click="updateDeliveryOption()"
          >
            {{ $globalTexts.checkout__delivery_date_submit }}
          </button>
        </div>
      </div>
    </modal-container>
  </teleport>
</template>

<style>
.checkout-delivery-date {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.checkout-delivery-date__top {
  position: relative;
  margin-top: 2rem;
}

.checkout-delivery-date__caption-container {
  position: absolute;
  top: -1.5rem;
  width: 100%;
  pointer-events: none;
}

.checkout-delivery-date__caption {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}

.checkout-delivery-date__delivery-options {
  margin-top: 2.5rem;
  padding-top: 1rem;
}

.checkout-delivery-date__delivery-options--shadow {
  box-shadow: 0px 3px 15px rgba(9, 30, 66, 0.08);
}

.checkout-delivery-date__shadow {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: -9000px;
  box-shadow: 0px 3px 15px rgba(9, 30, 66, 0.08);
  pointer-events: none;
}

.checkout-delivery-date__delivery-option {
  margin: 2rem 2rem 1rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #f6f6f6;
}

.checkout-delivery-date__delivery-option:last-child {
  border-bottom: none;
}

.checkout-delivery-date__date-picker {
  padding: 0 2rem;
}

.checkout-delivery-date__date-picker .date-picker {
  height: 350px;
}

.checkout-delivery-date__update-delivery {
  width: calc(100% - 2rem);
  margin: 1rem;
}
</style>