<script>
import { isSameDay } from 'date-fns';
import useContext from '@/composables/useContext';

export default {
  emits: ['openEditor'],
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    isMounted: false
  }),
  setup() {
    const { isAuthorized, navigateToLoginPage } = useContext();

    return { isAuthorized, navigateToLoginPage };
  },
  computed: {
    productName() {
      return this.row.baseProductName || '';
    },
    articleNumber() {
      return this.row.articleNumber || '';
    },
    hasPrint() {
      return this.row.printRow;
    },
    variantType() {
      let variant = '';
      if (this.row.color && this.row.size) {
        variant += this.row.size
          ? `${this.row.color}, ${this.row.size}`
          : this.row.color;
      } else if (this.row.size) {
        variant += this.row.size || '';
      } else {
        variant = this.row.variantName;
      }
      return variant;
    },
    quantity() {
      return this.row.quantity;
    },
    unitOfMeasurement() {
      return this.row?.unitOfMeasurement || '';
    },
    estimatedShippingDateTimeLabel() {
      const today = new Date();
      const estimatedShippingDate = this.estimatedShippingDateTime
        ? new Date(this.estimatedShippingDateTime)
        : today;

      if (isSameDay(today, estimatedShippingDate)) {
        return this.$globalTexts.global__available_today;
      }

      return this.$replaceTokens(
        this.$globalTexts?.checkout__available_date || '',
        {
          date: this.estimatedShippingDateTime
            ? `${this.$formatDate(
                new Date(this.estimatedShippingDateTime),
                'yyyy-MM-dd'
              )}`
            : ''
        }
      );
    },
    estimatedShippingDateTime() {
      if (this.row?.giftCardInput) {
        return this.row?.giftCardShippingDate
          ? new Date(this.row.giftCardShippingDate)
          : new Date();
      }
      return this.row?.stock?.estimatedShippingDateTime || '';
    }
  },
  methods: {
    getProductImageUrl() {
      if (!!this.row.giftCardInfo) {
        var addons = this.row.giftCardInfo.addons;
        var printessAddon = addons.find((a) => !!a.extraInfo);
        if (!!printessAddon) {
          var info = JSON.parse(printessAddon.extraInfo);
          return info.ThumbnailUrl;
        }
      }
      const imageHeight =
        this.$refs?.imageContainer?.getBoundingClientRect()?.height || 300;

      const imageId = this.row.giftCardImageId || this.row.imageId;

      return this.$toLitiumMediaUrl(imageId, { maxHeight: imageHeight }) || '';
    },
    openEditIfAuthorized() {
      if (this.row.giftCardInput || this.row.giftCardInfo) {
        return;
      }
      if (!this.isAuthorized()) {
        this.navigateToLoginPage();
        return;
      }

      this.$emit('openEditor', {
        id: this.row.rowGroupId,
        url: this.row.url,
        name: this.productName,
        articleNumber: this.row.articleNumber
      });
    }
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<template>
  <div
    :class="[
      'checkout-order-row',
      { 'checkout-order-row__gift-card': row.giftCardInput }
    ]"
    @click="openEditIfAuthorized()"
  >
    <div class="checkout-order-row__info">
      <div class="checkout-order-row__thumbnail-container">
        <div class="checkout-order-row__variant-image-overlay"></div>
        <img
          v-if="isMounted"
          :src="getProductImageUrl()"
          :alt="productName"
          loading="lazy"
          class="checkout-order-row__variant-image"
        />
      </div>

      <div class="checkout-order-row__details">
        <div>
          <div class="checkout-order-row__row-info">
            <div class="checkout-order-row__row-info-left">
              {{ productName }}
            </div>
            <div
              v-if="hasPrint"
              class="checkout-order-row__row-decoration-info"
            >
              {{ $globalTexts.decoration__decorated }}
            </div>
          </div>
          <div class="checkout-order-row__row-info">
            <div class="checkout-order-row__row-info-left">
              {{ variantType }} | {{ articleNumber }}
            </div>
          </div>
          <div class="checkout-order-row__row-info">
            <div class="checkout-order-row__row-info-left">
              {{ quantity }} {{ unitOfMeasurement }}
            </div>
            <div
              v-if="row.giftCardInput"
              class="checkout-order-row__row-info-right"
            >
              {{ estimatedShippingDateTimeLabel }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.checkout-order-row {
  background-color: #fff;
  cursor: pointer;
  margin-bottom: 6px;
}

.checkout-order-row__gift-card {
  cursor: default;
}

.checkout-order-row__availability {
  font-size: 13px;
  color: #6d6d8e;
  line-height: 18px;
}

.checkout-order-row__info {
  display: flex;
  position: relative;
}

.checkout-order-row__thumbnail-container {
  position: relative;
  width: 42px;
  height: 56px;
  user-select: none;
}

.checkout-order-row__thumbnail {
  padding-top: 130%;
}

.checkout-order-row__image-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  background-color: #efefef;
  mix-blend-mode: darken;
}

.checkout-order-row__variant-image {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  margin: auto;
}

.checkout-order-row__variant-image-overlay {
  z-index: 10;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.8;
  background-color: #efefef;
  mix-blend-mode: darken;
}

.checkout-order-row__details {
  width: 100%;
  padding: 0 0 0 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.checkout-order-row__row-info {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  letter-spacing: 0.015em;
  font-family: 'Mulish';
  color: #1a1a1a;
  line-height: 17px;
}

.checkout-order-row__row-decoration-info {
  font-style: italic;
}

.checkout-order-row__row-info-left {
  color: var(--color-text-darker);
}
</style>
