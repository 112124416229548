<script>
import ModalContainer from "./ModalContainer.vue";
import TextInput from "./forms/TextInput.vue";
import PhoneInput from "./forms/PhoneInput";
import Checkbox from "./forms/Checkbox.vue";

import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
} from "libphonenumber-js";
import { validateEmail } from "../utils.ts";

export default {
  components: {
    ModalContainer,
    TextInput,
    PhoneInput,
    Checkbox,
  },
  data: () => ({
    isOpen: false,
    isInEditMode: false,
    contactPerson: {},
  }),
  computed: {
    caption() {
      return this.isInEditMode
        ? this.$globalTexts.checkout__contact_edit
        : this.$globalTexts.checkout__contact_new;
    },
    requiredFieldCaption() {
      return this.$globalTexts.global__required_field;
    },
    invalidEmailCaption() {
      return this.$globalTexts.global__email_invalid;
    },
    invalidPhoneCaption() {
      return this.$globalTexts.global__invalid_phone;
    },
    isTempContact() {
      return !this.contactPerson?.systemId;
    },
    isAuthenticated() {
      return this.$user?.isAuthenticated || false;
    },
  },
  methods: {
    add() {
      this.contactPerson = {};
      this.open();
    },
    edit(contactPerson) {
      this.contactPerson = contactPerson;
      this.isInEditMode = true;
      this.open();
    },
    open() {
      this.isOpen = true;
      this.$nextTick(() => this.$refs?.firstNameInput?.focus());
    },
    close() {
      this.isOpen = false;
    },
    onFirstNameFocus() {
      this.contactPerson.firstNameError = "";
      this.onLastNameFocus();
    },
    onFirstNameBlur() {
      if (!this.contactPerson.firstName) {
        this.contactPerson.firstNameError = this.requiredFieldCaption;
      }
    },
    onLastNameFocus() {
      this.contactPerson.lastNameError = "";
      this.onEmailFocus();
    },
    onLastNameBlur() {
      if (!this.contactPerson.lastName) {
        this.contactPerson.lastNameError = this.requiredFieldCaption;
      }
    },
    onEmailFocus() {
      this.contactPerson.emailError = "";
      this.onPhoneInputFocus();
    },
    onEmailBlur() {
      if (!this.contactPerson.email) {
        this.contactPerson.emailError = this.requiredFieldCaption;
      }
      if (!validateEmail(this.contactPerson.email)) {
        this.contactPerson.emailError = this.invalidEmailCaption;
      }
    },
    onPhoneInputFocus() {
      this.contactPerson.phoneError = "";
    },
    onPhoneInputBlur() {
      if (!this.contactPerson.phone) {
        this.contactPerson.phoneError = this.requiredFieldCaption;
      }

      try {
        if (
          !isValidPhoneNumber(
            this.contactPerson.phone,
            this.contactPerson.phoneCountry
          )
        ) {
          this.contactPerson.phoneError = this.invalidPhoneCaption;
        }
      } catch (err) {
        this.contactPerson.phoneError = this.invalidPhoneCaption;
      }
    },

    updatePhoneNumber(phone) {
      try {
        const parsedPhone = parsePhoneNumberFromString(phone);
        this.contactPerson.phoneCountry = parsedPhone?.country;
      } catch (err) {}

      this.contactPerson.phone = phone;
    },
    validateContactPerson() {
      let isValid = true;

      if (!this.contactPerson.firstName) {
        this.contactPerson.firstNameError = this.requiredFieldCaption;
        isValid = false;
      }

      if (!this.contactPerson.lastName) {
        this.contactPerson.lastNameError = this.requiredFieldCaption;
        isValid = false;
      }

      if (!this.contactPerson.email) {
        this.contactPerson.emailError = this.requiredFieldCaption;
        isValid = false;
      }

      if (!validateEmail(this.contactPerson.email)) {
        this.contactPerson.emailError = this.invalidEmailCaption;
        isValid = false;
      }

      if (!this.contactPerson.phone) {
        this.contactPerson.phoneError = this.requiredFieldCaption;
        isValid = false;
      }

      try {
        if (
          !isValidPhoneNumber(
            this.contactPerson.phone,
            this.contactPerson.phoneCountry
          )
        ) {
          this.contactPerson.phoneError = this.invalidPhoneCaption;
          isValid = false;
        }
      } catch (err) {
        this.contactPerson.phoneError = this.invalidPhoneCaption;
        isValid = false;
      }

      return isValid;
    },
    setContactPerson() {
      if (this.validateContactPerson()) {
        this.$emit("update:contactPerson", this.contactPerson);
      }
    },
  },
};
</script>

<template>
  <teleport to="body">
    <modal-container
      @close-modal="close"
      @navigate-back="close"
      :show-exit-button="!$isPhone"
      :show-back-button="$isPhone"
      position="center"
      v-if="isOpen"
    >
      <div class="delivery-contactPerson-form">
        <div class="delivery-contactPerson-form__caption">
          {{ caption }}
        </div>
        <div class="delivery-contactPerson-form__form">
          <text-input
            id="contactPersonFirstName"
            :label="$globalTexts.global__first_name"
            @focus="onFirstNameFocus"
            @blur="onFirstNameBlur"
            v-model="contactPerson.firstName"
            :error-message="contactPerson.firstNameError"
            :autoselect="true"
            class="delivery-contactPerson-form__form-row"
            ref="firstNameInput"
          />
          <text-input
            id="contactPersonLastName"
            :label="$globalTexts.global__last_name"
            @focus="onLastNameFocus"
            @blur="onLastNameBlur"
            v-model="contactPerson.lastName"
            :error-message="contactPerson.lastNameError"
            :autoselect="true"
            class="delivery-contactPerson-form__form-row"
          />
          <text-input
            id="contactPersonEmail"
            :label="$globalTexts.global__email"
            @focus="onEmailFocus"
            @blur="onEmailBlur"
            v-model="contactPerson.email"
            :error-message="contactPerson.emailError"
            :autoselect="true"
            class="delivery-contactPerson-form__form-row"
          />
          <div class="delivery-contactPerson-form__form-row">
            <label class="person-profile__label">{{ phoneCaption }}</label>
            <phone-input
              :value="contactPerson.phone"
              :selected-country="$cart.countryId"
              :placeholder="$globalTexts.global__phone"
              :error-message="contactPerson.phoneError"
              @input="updatePhoneNumber"
              @focus="onPhoneInputFocus"
              @blur="onPhoneInputBlur"
            />
          </div>
          <div class="delivery-address-form__checkbox">
            <checkbox
              v-model="contactPerson.saveContact"
              :text="$globalTexts.checkout__save_for_future"
              v-if="isTempContact && isAuthenticated"
            />
          </div>

          <button
            class="primary-button delivery-contactPerson-form__submit"
            @click="setContactPerson()"
          >
            {{ $globalTexts.checkout__contact_submit }}
          </button>
        </div>
      </div>
    </modal-container>
  </teleport>
</template>

<style>
.delivery-contactPerson-form {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.delivery-contactPerson-form__caption {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin-top: 0.75rem;
}

.delivery-contactPerson-form__form {
  padding: 1rem;
}

.delivery-contactPerson-form__form-row {
  padding-top: 1rem;
}

.delivery-contactPerson-form__checkbox {
  padding-top: 1.25rem;
}

.delivery-contactPerson-form__form-row--inline {
  display: flex;
}

.delivery-contactPerson-form__contactPerson2 {
  padding-top: 5px;
}

.delivery-contactPerson-form__zip-code {
  width: calc(30% - 10px);
  margin-right: 10px;
}

.delivery-contactPerson-form__city {
  width: 70%;
}

.delivery-contactPerson-form__submit {
  width: 100%;
  margin-top: 2rem;
}

@media (--tabletAndDesktop) {
  .delivery-contactPerson-form {
    overflow: initial;
  }
}
</style>