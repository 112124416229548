<script>

export default {
  computed: {
    organizationName() {
      return this.$user?.organization?.organizationName || '';
    },
    address() {
      return this.$user?.organization?.address || '';
    },
    address2() {
      return this.$user?.organization?.address2 || '';
    },
    postCode() {
      return this.$user?.organization?.postCode || '';
    },
    city() {
      return this.$user?.organization?.city || '';
    },
    cityAndPostCode() {
      if (this.postCode) {
        if (this.city) {
          return `${this.postCode}, ${this.city}`;
        }

        return this.postCode;
      }
      else if (this.city) {
        return this.city;
      }

      return '';
    },
    countries() {
      return this.$user?.organization?.countries || [];
    },
    country() {
      const countryCode = this.$user?.organization?.country || '';
      return this.countries.find(c => c.value === countryCode)?.text || '';
    },
    currency() {
      return this.$user?.organization?.currency || '';
    },
    cartCurrency() {
      return this.$cart.currency?.id;
    },
    currencyMismatch() {
      return this.currency !== this.cartCurrency;
    },
    currencyMismatchMessage() {
      return this.$replaceTokens(this.$globalTexts.checkout__currency_mismatch, {
        currency: this.cartCurrency,
      });
    }
  },
};
</script>

<template>
  <div class="checkout-customer-invoice-address">
    <div 
      class="checkout-customer-invoice-address__title"
    >
    {{ $globalTexts.orderdetails__invoice_address }}
    </div>
    <div v-if="organizationName" class="checkout-customer-invoice-address__highlight">{{ organizationName }}</div>
    <div v-if="address" class="checkout-customer-invoice-address__text">{{ address }}</div>
    <div v-if="address2" class="checkout-customer-invoice-address__text">{{ address2 }}</div>
    <div v-if="cityAndPostCode" class="checkout-customer-invoice-address__text">{{ cityAndPostCode }}</div>
    <div v-if="country" class="checkout-customer-invoice-address__text">{{ country }}</div>
    <div v-if="currencyMismatch" class="checkout-customer-invoice-address__warning">{{ currencyMismatchMessage }}</div>
  </div>
</template>

<style>

  .checkout-customer-invoice-address {
    font-family: Mulish;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-neutrals-60);
  }

  .checkout-customer-invoice-address__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    padding: 1rem 0 0.5rem 0;
    color: var(--color-main-primary);
  }

  .checkout-customer-invoice-address__highlight {
    font-weight: 700;
    color: var(--color-text-dark);
    padding-left: 10px;
  }

  .checkout-customer-invoice-address__text {
    padding-left: 10px;
  }

  .checkout-customer-invoice-address__warning {
    font-size: 12px;
    color: var(--color-text-darker);
    line-height: 26px;
  }
</style>
