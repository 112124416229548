<script>
import ModalContainer from "../ModalContainer.vue";

import CheckoutOrderInfo from "./CheckoutOrderInfo";
import CheckoutCustomerInfo from "./CheckoutCustomerInfo";
import CheckoutOrderSummary from "./CheckoutOrderSummary";

const uiSteps = Object.freeze({
  Products: Symbol("Products"),
  OrderDetails: Symbol("OrderDetails"),
});

export default {
  components: {
    CheckoutOrderInfo,
    ModalContainer,
    CheckoutCustomerInfo,
    CheckoutOrderSummary,
  },
  data: () => ({
    uiSteps: uiSteps,
    uiStep: null,
    placeOrderError: "",
  }),
  computed: {
    cartIsEmpty() {
      return this.$cart?.isEmpty || false;
    },
    showExitButton() {
      return this.uiStep === this.uiSteps.Products;
    },
    showBackButton() {
      return this.uiStep === this.uiSteps.OrderDetails;
    },
  },
  methods: {
    navigateBack() {
      history.back();
    },
    loadFirstStep() {
      this.uiStep = this.uiSteps.Products;
    },
    loadFinalStep() {
      this.uiStep = this.uiSteps.OrderDetails;
    },
    onPlaceOrderFailed(error) {
      this.placeOrderError = error;
    },
  },
  mounted() {
    this.uiStep = this.uiSteps.Products;
    this.$mitt.on("checkout:placeOrderFailed", this.onPlaceOrderFailed);
  },
  beforeUnmount() {
    this.$mitt.off("checkout:placeOrderFailed", this.onPlaceOrderFailed);
  },
};
</script>

<template>
  <teleport to="body">
    <modal-container
      @close-modal="navigateBack"
      @navigate-back="loadFirstStep"
      :show-back-button="showBackButton"
      :show-exit-button="showExitButton"
      class="checkout-phone__modal"
    >
      <div class="checkout-phone">
        <div class="checkout-phone__caption">
          {{ $globalTexts.checkout__title }}
        </div>
        <div class="checkout-phone__steps" v-if="!cartIsEmpty">
          <div
            v-if="uiStep == uiSteps.Products"
            class="checkout-phone__order-info-step"
          >
            <div class="checkout-phone__order-info">
              <checkout-order-info
                @update:deliveryOption="$emit('update:deliveryOption', $event)"
              />
            </div>

            <div class="checkout-phone__next-step">
              <button class="primary-button" @click="loadFinalStep()">
                <div class="checkout-phone__next-step-content">
                  <div class="checkout-phone__next-step-caption">
                    {{ $globalTexts.checkout__next }}
                  </div>
                  <div class="checkout-phone__next-step-icon">
                    <img
                      class="product-block__scroll-left-icon"
                      src="../../static/icons/scroll_arrow_white_right.svg"
                    />
                  </div>
                </div>
              </button>
            </div>
          </div>
          <div
            v-if="uiStep == uiSteps.OrderDetails"
            class="checkout-phone__order-last-step"
          >
            <div class="checkout-phone__order-details">
              <checkout-customer-info
                @update:address="$emit('update:address', $event)"
              />
              <checkout-order-summary />
            </div>

            <div class="checkout-phone__last-step">
              <div
                class="checkout-phone__place-order-error"
                v-if="placeOrderError"
              >
                {{ placeOrderError }}
              </div>
              <button
                class="primary-button"
                @click="
                  $mitt.emit('place-order');
                  placeOrderError = '';
                "
              >
                {{ $globalTexts.checkout__submit_order }}
              </button>
            </div>
          </div>
        </div>
        <div v-else class="checkout-phone__empty">
          {{ $globalTexts.global__label_empty_cart }}
        </div>
      </div>
    </modal-container>
  </teleport>
</template>

<style>
.checkout-phone {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.checkout-phone__caption {
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.checkout-phone__empty {
  padding-top: 2rem;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.checkout-phone__steps {
  width: 100%;
  height: 100%;
}

.checkout-phone__order-info-step,
.checkout-phone__order-last-step {
  display: flex;
  flex-direction: column;
  width: 100%;
  right: 0;
  z-index: 9999;
  height: calc(100% - 3.5rem);
  padding-top: 0.5rem;
}

.checkout-phone__order-info,
.checkout-phone__order-details {
  overflow-y: auto;
  max-height: 100%;
}

.checkout-phone__last-step,
.checkout-phone__next-step {
  margin-top: auto;
  background-color: #fff;
  width: calc(100% - 2rem);
  margin-left: 1rem;
}

.checkout-phone__last-step button,
.checkout-phone__next-step button {
  width: 100%;
}

.checkout-phone__next-step button {
  position: relative;
}

.checkout-phone__next-step-caption {
  margin-left: auto;
}

.checkout-phone__next-step-icon {
  position: absolute;
  right: 12px;
  top: 12px;
}

.checkout-phone__place-order-error {
  display: block;
  color: var(--color-feedback-error);
  text-align: center;
  padding-bottom: 1rem;
  font-size: 14px;
  font-weight: 700;
}

@media (--tabletAndDesktop) {
  .checkout-phone__modal {
    display: none;
  }
}
</style>