<script>
import CheckoutOrderInfo from "./CheckoutOrderInfo";
import CheckoutCustomerInfo from "./CheckoutCustomerInfo";
import CheckoutOrderSummary from "./CheckoutOrderSummary";

export default {
  components: {
    CheckoutOrderInfo,
    CheckoutCustomerInfo,
    CheckoutOrderSummary,
  },
  computed: {
    cartIsEmpty() {
      return this.$cart?.isEmpty || false;
    }
  },
  methods: {
    navigateBack(){
      history.back();
    }
  }
};
</script>

<template>
  <div class="checkout-desktop__actions">
    <div class="checkout-desktop__go-back" @click="navigateBack()">
      <img
        class="checkout-desktop__go-back-icon"
        src="../../static/icons/arrow_left_icon.svg"
      />
      <div class="checkout-desktop__go-back-caption">
        {{ $globalTexts.global__navigate_back }}
      </div>
    </div>
  </div>
  <div class="checkout-desktop">
    <div class="checkout-desktop__caption">
      {{ $globalTexts.checkout__title }}
    </div>
    <div class="checkout-desktop__wrapper" v-if="!cartIsEmpty">
      <checkout-order-info
        class="checkout-desktop__order-info"
        @update:deliveryOption="$emit('update:deliveryOption', $event)"
      />
      <checkout-customer-info
        class="checkout-desktop__customer-info"
        @update:address="$emit('update:address', $event)"
      />
      <checkout-order-summary class="checkout-desktop__order-summary" />
    </div>
    <div v-else class="checkout-desktop__empty">
      {{$globalTexts.global__label_empty_cart}}
    </div>
  </div>
</template>

<style>
.checkout-desktop {
  max-width: 1260px;
  margin: 0 auto;
}

.checkout-desktop__actions {
  padding: 20px 20px 20px 50px;
  box-shadow: var(--shadow-outer-05);
  background-color: white;
  display: flex;
}

.checkout-desktop__go-back {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkout-desktop__go-back-caption {
  padding-left: 0.5rem;
}

.checkout-desktop__caption {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  padding: 1.5rem 0;
}

.checkout-desktop__wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
}

.checkout-desktop__order-info,
.checkout-desktop__customer-info,
.checkout-desktop__order-summary {
  width: 33.3%;
  margin: 0 0.75rem;
  height: fit-content;
  height: -moz-fit-content;
}

.checkout-desktop__empty {
  padding-top: 2rem;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
}
</style>