<script>
import TextInput from '../forms/TextInput';
import CheckoutCustomerInfoAddress from './CheckoutCustomerInfoAddress';
import CheckoutCustomerInfoContact from './CheckoutCustomerInfoContact';
import CheckoutCustomerInvoiceAddress from './CheckoutCustomerInvoiceAddress';

export default {
  components: {
    TextInput,
    CheckoutCustomerInfoAddress,
    CheckoutCustomerInfoContact,
    CheckoutCustomerInvoiceAddress
  },
  data: () => ({
    noteOfGoodModel: '',
    commentModel: '',
    deliveryContactPersonModel: '',
  }),
  computed: {
    isAuthenticated() {
      return this.$user?.isAuthenticated || false;
    },
  },
  mounted() {
    this.noteOfGoodModel = sessionStorage.getItem('checkout:noteOfGoods')
      || this.$cart?.noteOfGoods
      || '';

    this.commentModel = sessionStorage.getItem('checkout:comments') || this.$cart?.comments || '';
    this.deliveryContactPersonModel = sessionStorage.getItem('checkout:deliveryContact') || this.$cart?.deliveryContact || '';
  },
};
</script>

<template>
  <div class="checkout-customer-info">
    <div class="checkout-customer-info__caption">
      2. {{ $globalTexts.checkout__information_title }}
    </div>
    <div class="checkout-customer-info__goods">
      <text-input
        :label="$globalTexts.checkout__goods_label"
        v-model="noteOfGoodModel"
        :autoselect="true"
        @input="$mitt.emit('update:noteOfGoods', noteOfGoodModel)"
      />
    </div>

    <checkout-customer-info-contact />

    <checkout-customer-invoice-address />

    <div class="checkout-customer-info__separator" />

    <div class="checkout-customer-info__container">
      <div class="checkout-customer-info__container-label">
        {{ $globalTexts.checkout__delivery_contact }}
      </div>
      <text-input
        type="text" 
        :maxlength="50"
        :placeholder="$globalTexts.checkout__comment_optional"
        v-model="deliveryContactPersonModel"
        @input="$mitt.emit('update:deliveryContact', deliveryContactPersonModel)"
      />
    </div>

    <checkout-customer-info-address />

    <div class="checkout-customer-info__separator" />

    <div class="checkout-customer-info__container">
      <div class="checkout-customer-info__container-label">
        {{ $globalTexts.checkout__comment }}
      </div>
      <text-input
        type="textarea"
        :rows="4"
        :label="$globalTexts.checkout__comment_description"
        :maxlength="80"
        :placeholder="$globalTexts.checkout__comment_optional"
        v-model="commentModel"
        @input="$mitt.emit('update:comments', commentModel)"
      />
    </div>

  </div>
</template>

<style>
.checkout-customer-info {
  background-color: white;
  padding: 1rem;
}

.checkout-customer-info__caption {
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  padding: 0 0 0.5rem 0;
  display: none;
}

.checkout-customer-info__container-label {
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  padding: 1rem 0 0.5rem 0;
}

.checkout-customer-info__separator {
  border-top-color: var(--color-border-50);
  border-top-width: 1px;
  border-top-style: solid;
  margin-top: 1rem;
}

@media (--tabletAndDesktop) {
  .checkout-customer-info {
    padding: 30px;
    box-shadow: var(--shadow-outer-05);
  }

  .checkout-customer-info__caption {
    display: block;
  }
}
</style>
