<script>
import Dropdown from "../forms/Dropdown";
import ContactPersonForm from "../ContactPersonForm";

export default {
  components: {
    Dropdown,
    ContactPersonForm,
  },
  data: () => ({
    contactPersonId: "",
    temporaryContacts: [],
  }),
  computed: {
    contactOptions() {
      let savedContacts = [];
      if (this.$cart?.contacts) {
        savedContacts = (this.$cart?.contacts || []).map((a) => {
          a.value = a.systemId;
          return a;
        });
      }

      let ownContact = savedContacts.find(
        (f) => f.email && f.email === this.$user?.person?.email
      );

      if (!ownContact) {
        if (this.$user?.person) {
          ownContact = {
            email: this.$user?.person?.email,
            firstName: this.$user?.person?.firstName,
            lastName: this.$user?.person?.lastName,
            value: this.$formatDate(new Date(), "yyyy-MM-dd:HH:mm:ss"),
            systemId: "",
            isNotEditable: true,
          };

          savedContacts.unshift(ownContact);
        }
      } else {
        ownContact.isNotEditable = true;
        savedContacts.splice(savedContacts.indexOf(ownContact), 1);
        savedContacts.unshift(ownContact);
      }

      return [...this.temporaryContacts, ...savedContacts];
    },
    customerAddress() {
      return this.$cart?.customerAddress;
    },
    selectedContact() {
      return this.contactOptions.find(c => c.systemId == this.contactPersonId);
    },
    selectedContactEmail() {
      return this.selectedContact?.email || '';
    },
    selectedContactEmailMessage() {
      return this.$replaceTokens(this.$globalTexts.checkout__order_confirmation_mail, {
        email: this.selectedContactEmail,
      });
    },
    selectedContactEmailMissing() {
      return this.selectedContactEmail === '';
    },
    selectedContactEmailMissingMessage() {
      return this.$globalTexts.checkout__order_confirmation_mail_missing;
    }
  },
  methods: {
    addOption(e) {
      this.$refs?.contactPersonDropdown?.shrink();
      this.$refs.contactPersonForm.add();
    },
    editOption(option, e) {
      this.$refs?.contactPersonDropdown?.shrink();
      this.$refs.contactPersonForm.edit(option);
    },
    updateContactPerson(contact) {
      if (!contact.systemId) {
        const idx = this.temporaryContacts.findIndex(
          (r) => r.value == contact.value
        );

        if (idx !== -1) {
          this.temporaryContacts.splice(idx, 1);
        }

        if (!contact.saveContact) {
          contact.value = this.$formatDate(new Date(), "yyyy-MM-dd HH:mm:ss");
          this.temporaryContacts.unshift(contact);
        }

        sessionStorage.setItem(
          "checkout:temporaryContacts",
          JSON.stringify(this.temporaryContacts)
        );

        this.contactPersonId = contact.value;
      } else {
        if(!contact.isNotEditable){
          contact.saveContact = true;
        }        
      }

      this.$mitt.emit("update:checkoutContactPerson", contact);
    },
    closeContactPersonForm() {
      this.$refs?.contactPersonForm?.close();
      this.$refs?.contactPersonDropdown?.shrink();

      if (this.$cart?.contactPersonId) {
        this.contactPersonId = this.$cart?.contactPersonId;
      }
    },
  },
  mounted() {
    this.temporaryContacts = JSON.parse(
      sessionStorage.getItem("checkout:temporaryContacts") || "[]"
    );

    this.contactPersonId =
      this.$cart?.contactPersonId ||
      this.contactOptions?.find(
        (f) => f.systemId && f.email === this.$user?.person?.email
      )?.systemId ||
      this.contactOptions[0]?.value ||
      "";

    const option = this.contactOptions?.find(
      (f) => this.contactPersonId && f.value == this.contactPersonId
    );
    if (option) {
      this.updateContactPerson(option);
    }
    this.$mitt.on("updated:checkoutContactPerson", this.closeContactPersonForm);
  },
  beforeUnmount() {
    this.$mitt.off(
      "updated:checkoutContactPerson",
      this.closeContactPersonForm
    );
  },
};
</script>

<template>
  <div class="checkout-customer-info-contact">
    <div class="checkout-customer-info-contact__caption">
      {{ $globalTexts.checkout__contact_person }}
    </div>
    <dropdown
      v-model="contactPersonId"
      :add-option="true"
      :label="$globalTexts.checkout__contact_person_label"
      :options="contactOptions"
      height="auto"
      :placeholder="$globalTexts.global__add_new_label"
      @change="updateContactPerson"
      @add-option="addOption"
      :shrink-on-select="false"
      ref="contactPersonDropdown"
    >
      <template v-slot:selected="slotProps">
        <div class="checkout-customer-info-contact__selected">
          <div
            v-if="slotProps.selected && slotProps.selected.value"
            class="checkout-customer-info-contact__item"
          >
            <div
              class="
                checkout-customer-info-contact__info-row
                checkout-customer-info-contact__info-row--bold
              "
            >
              {{ slotProps.selected.firstName }}
              {{ slotProps.selected.lastName }}
            </div>
            <div class="checkout-customer-info-contact__info-row">
              {{ slotProps.selected.email }}
            </div>
            <div class="checkout-customer-info-contact__info-row">
              {{ slotProps.selected.phone }}
            </div>
          </div>
          <div class="checkout-customer-info-contact__placeholder" v-else>
            {{ slotProps.placeholder }}
          </div>
        </div>
      </template>
      <template v-slot:option="slotProps">
        <div
          class="
            checkout-customer-info-contact__item
            checkout-customer-info-contact__option
          "
        >
          <div
            class="
              checkout-customer-info-contact__item
              checkout-customer-info-contact__option-rows
            "
          >
            <div
              class="
                checkout-customer-info-contact__info-row
                checkout-customer-info-contact__info-row--bold
              "
            >
              {{ slotProps.option.firstName }} {{ slotProps.option.lastName }}
            </div>
            <div class="checkout-customer-info-contact__info-row">
              {{ slotProps.option.email }}
            </div>
            <div class="checkout-customer-info-contact__info-row">
              {{ slotProps.option.phone }}
            </div>
          </div>

          <div
            class="
              checkout-customer-info-contact__item
              checkout-customer-info-contact__option-actions
            "
          >
            <button
              class="secondary-button checkout-customer-info-contact__option-actions-button"
              @click.stop="editOption(slotProps.option, $event)"
              v-if="!slotProps.option.isNotEditable"
            >
              {{ $globalTexts.global__edit }}
            </button>
          </div>
        </div>
      </template>
    </dropdown>

    <div
      class="checkout-customer-info-contact__message"
      v-if="!selectedContactEmailMissing"
      v-html="selectedContactEmailMessage"
    />

    <div
      class="checkout-customer-info-contact__message"
      v-if="selectedContactEmailMissing"
      v-html="selectedContactEmailMissingMessage"
    />
  </div>
  <contact-person-form
    ref="contactPersonForm"
    @update:contactPerson="updateContactPerson"
  />
</template>

<style>
.checkout-customer-info-contact .dropdown__placeholder{
  line-height: 40px;
}

.checkout-customer-info-contact__caption {
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  padding: 1rem 0 0.5rem 0;
  display: none;
}

.checkout-customer-info-contact__item {
  padding: 0.5rem 0;
}

button.checkout-customer-info-contact__option-actions-button {
  padding: 12px 16px;
}

.checkout-customer-info-contact .dropdown__new-option {
  padding-bottom: 1rem;
}

.checkout-customer-info-contact .dropdown__new-option,
.checkout-customer-info-contact__option {
  border-bottom: 1px solid #e6e6e6;
}

.checkout-customer-info-contact__info-row {
  font-size: 14px;
  line-height: 24px;
  color: #9d9fa6;
  font-weight: 400;
}

.checkout-customer-info-contact__info-row--bold {
  color: #1a1a1a;
  font-weight: 700;
}

.checkout-customer-info-contact__placeholder {
  font-weight: 400;
}

.checkout-customer-info-contact .dropdown__option {
  padding: 0 13px;
}

.checkout-customer-info-contact__option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkout-customer-info-contact__message {
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.18px;
  color: #1A1A1A;
}

@media (--tabletAndDesktop) {
  .checkout-customer-info-contact__caption {
    display: block;
  }
}
</style>